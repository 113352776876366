import { Grid, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const DividerStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  margin: "auto",
  maxWidth: "30%",
  borderBottom: `5px solid ${theme.palette.primary.main}`,

  [theme.breakpoints.down("xs")]: {
    maxWidth: "2%",
  },

  [theme.breakpoints.down("lg")]: {
    maxWidth: "20%",
  },

  [theme.breakpoints.down("sm")]: {
    maxWidth: "15%",
  },
}));

const Growth = () => {
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <DividerStyle variant="middle" />
            <Typography variant="h4" textAlign="center">
              Embracing Growth and Expansion
            </Typography>
            <DividerStyle variant="middle" />
          </Box>
          <Typography variant="body1" textAlign="center" mt={5} paragraph>
            Our vision for the future includes expanding our business by
            developing essential products that extend their reach and enhance
            productivity.
          </Typography>

          <Grid container p={5}>
            <Grid item xs={12} sm={6} md={3} mt={2} textAlign="center">
              <Typography variant="h3">5K+</Typography>
              <Typography variant="body2">
                Villages under our coverage
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} mt={2} textAlign="center">
              <Typography variant="h3">55K+</Typography>
              <Typography variant="body2">
                A network of retail touchpoints
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} mt={2} textAlign="center">
              <Typography variant="h3">20L+</Typography>
              <Typography variant="body2">A customer base exceeding</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3} mt={2} textAlign="center">
              <Typography variant="h3">15+</Typography>
              <Typography variant="body2">
                A diverse range of products and services.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Growth;
