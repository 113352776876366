import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CustomButton from "../UI/CustomButton";

const ImageSection = styled(Box)(() => ({
  textAlign: "center",
}));

const ContactBanner = () => {
  const navigate = useNavigate();

  const navigateTo = (route) => {
    navigate(route);
  };
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4" textAlign={"center"} mt={3}>
            Do You Have Something in Mind?
          </Typography>
          <ImageSection>
            <img src="/connect_with_us.png" style={{ width: 380 }} />
          </ImageSection>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant="h4" color={"primary"}>
            "No need to fret! Reach out and connect with us to say hello."
          </Typography>
          <CustomButton
            shape={"round"}
            variant="outlined"
            size={"large"}
            style={{ marginTop: 10 }}
            onClick={() => navigateTo("contact-us")}
          >
            Reach Out to Us
          </CustomButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactBanner;
