import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { green } from "@mui/material/colors";

// Define your base theme
const baseTheme = createTheme({
  palette: {},
  spacing: 8,
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

// Make the base theme responsive
const theme = responsiveFontSizes(baseTheme, {
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
  factor: 6,
  variants: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "button",
    "caption",
  ],
});

// Light Theme
const lightTheme = createTheme({
  ...theme,
  palette: {
    primary: {
      main: "#026f71",
    },
    success: {
      main: green[700],
    },
    secondary: {
      main: "#000",
      nav: "gray",
    },
    background: {
      primary: "#fff",
      secondary: "#ebebeb",
      footer: "#ebebeb",
      header: "#fff",
      association: "#d3eadb",
      bankingshop: "#ffdddd",
      bannercontent: "#d4ebff",
      tiles: [
        "#b5d082",
        "#cae1f7",
        "#D9E3E9",
        "#c5bedc",
        "#87b3b4",
        "#95a48d",
        "#f7e9a9",
        "#f3ccc3",
        "#f5a623",
        "#b2967d",
        "#e7b3e4",
        "#96d1ca",
      ],

      tagLinegradient: ["#87b3b4", "#95a48d"],
    },
    mode: "light",
  },
  themeMode: "light",
});

// Dark Theme
const darkTheme = createTheme({
  ...theme,
  palette: {
    primary: {
      main: "#026f71",
      logo: "#56fcff",
    },
    success: {
      main: green[500],
    },
    secondary: {
      main: "#fff",
      nav: "gray",
    },
    background: {
      primary: "#272727",
      secondary: "#444444",
      footer: "#444444",
      association: "#859a68",
      header: "#272727",
      bankingshop: "#958383",
      bannercontent: "#404f5c",
      tiles: [
        "#35404A",
        "#506C7F",
        "#3D4451",
        "#4d554c",
        "#4F5A65",
        "#3e4431",
        "#685d79",
        "#2d3a45",
        "#5E6370",
        "#7A899E",
        "#56685F",
        "#364249",
      ],

      tagLinegradient: ["#677059", "#010700"],
    },
    mode: "dark",
  },
  themeMode: "dark",
});

export { lightTheme, darkTheme };
