import { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CircleOutlined, Circle } from "@mui/icons-material";
import CustomButton from "../UI/CustomButton";

const ResponsiveImage = styled("img")(({ theme }) => ({
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const ContentGridStyle = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.bannercontent,
  borderRadius: "45% 100% 58% 100%",
  marginTop: 70,
  position: "relative",

  animation: "moveBorderRadius 15s linear infinite",
  "@keyframes moveBorderRadius": {
    "0%": {
      borderRadius: "45% 100% 58% 100%",
    },
    "25%": {
      borderRadius: "60% 50% 30% 600%",
    },
    "50%": {
      borderRadius: "40% 80% 60% 90%",
    },
    "75%": {
      borderRadius: "40% 10% 60% 20%",
    },
    "100%": {
      borderRadius: "30% 10% 58% 100%",
    },
  },
}));

function Banner() {
  const [currentBanner, setCurrentBanner] = useState(0);
  const banners = [
    {
      imageUrl: "banner1.png",
      heading: "India’s Leading Online Banking Platform",
      content:
        "At Geopay, we take pride in being India's leading banking platform. We offer a wide range of financial services, all conveniently accessible through a single platform. Our commitment to serving communities across India sets us apart.",
      buttonLabel: "Register",
    },
    {
      imageUrl: "banner2.png",
      heading: "Unlock a World of Convenience",
      content:
        "The advantage of Geopay is that we empower you to provide multiple services, all in one place. Whether you're looking for traditional banking, seamless bill payment, quick mobile recharges, or travel bookings Geopay has you covered. Our comprehensive range of services allows you to cater to the diverse needs of your customers.",
      buttonLabel: "Get Started",
    },
    {
      imageUrl: "banner3.png",
      heading: "Join Geopay Today",
      content: `Ready to experience the benefits of Geopay? Join us today and become a part of India's neo banking revolution. With Geopay, you're not just providing financial services; you're shaping the future of banking. Our commitment to innovation, security, and customer-centric solutions sets us apart in the industry.

        Geopay is your gateway to a seamless and secure banking experience. Whether you're an individual looking for convenient digital banking or a business seeking efficient financial solutions, we have you covered. Our user-friendly platform and cutting-edge technology ensure that managing your finances has never been easier.`,
      buttonLabel: "Join Geopay",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
    }, 8000);

    return () => clearInterval(interval);
  }, [banners.length]);

  const { imageUrl, heading, content, buttonLabel } = banners[currentBanner];

  return (
    <>
      <Grid container spacing={8}>
        <ContentGridStyle item md={6}>
          <Box>
            <Typography variant="h3" color="primary">
              {heading}
            </Typography>
            <Typography variant="subtitle1" mt={2} mb={3}>
              {content}
            </Typography>
            <CustomButton variant="contained" size="large">
              {buttonLabel}
            </CustomButton>
          </Box>
        </ContentGridStyle>
        <Grid item md={6} style={{ width: "100%" }}>
          <Box mt={-5} style={{ textAlign: "center" }}>
            <ResponsiveImage src={`/${imageUrl}`} alt={heading} />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} textAlign={"center"}>
          <Box>
            {banners.map((_, index) =>
              currentBanner === index ? (
                <Circle
                  key={index}
                  color="primary"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <CircleOutlined
                  key={index}
                  onClick={() => setCurrentBanner(index)}
                  style={{ cursor: "pointer" }}
                />
              )
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Banner;
