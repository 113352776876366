import { Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const ResponsiveBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(12),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },
}));

function TermCondition() {
  return (
    <Grid container spacing={12}>
      <Grid item xs={12}>
        <ResponsiveBox>
          <Typography variant="h4" gutterBottom>
            Terms and Conditions
          </Typography>

          <Typography variant="body1">
            Welcome to www.geopaypayment.com!
          </Typography>

          <Typography variant="body1">
            These terms and conditions outline the rules and regulations for
            using Geopay Payment Private Limited's website, accessible at
            www.geopaypayment.com.
          </Typography>

          <Typography variant="body1">
            By accessing this website, we assume you agree to abide by these
            terms and conditions. If you do not agree to all the terms and
            conditions stated on this page, please refrain from using
            www.geopaypayment.com.
          </Typography>
          <Typography variant="body1">
            The following terminology applies to these Terms and Conditions,
            Privacy Statement, and Disclaimer Notice, as well as all Agreements:
            "Client," "You," and "Your" refer to you, the person visiting this
            website and adhering to the Company’s terms and conditions. "The
            Company," "Ourselves," "We," "Our," and "Us" refer to our Company.
            "Party," "Parties," or "Us" refer to both the Client and ourselves.
            All terms refer to the offer, acceptance, and consideration of
            payment necessary to undertake the process of our assistance to the
            Client in the most appropriate manner for the express purpose of
            meeting the Client’s needs in respect of provision of the Company’s
            stated services, in accordance with and subject to prevailing Dutch
            law. Any use of the above terminology or other words in the
            singular, plural, capitalization, and/or he/she or they, are taken
            as interchangeable and therefore as referring to the same.
          </Typography>

          <Typography variant="h5" mt={4}>
            Cookies
          </Typography>
          <Typography variant="body1">
            We use cookies. By accessing www.geopaypayment.com, you agree to the
            use of cookies in accordance with Geopay Payment Private Limited's
            Privacy Policy.
          </Typography>

          <Typography variant="body1">
            Most interactive websites employ cookies to retrieve user details
            for each visit. Our website uses cookies to enable the functionality
            of specific areas, making it easier for visitors. Some of our
            affiliate/advertising partners may also use cookies.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            License
          </Typography>

          <Typography variant="body1">
            Unless stated otherwise, Geopay Payment Private Limited and/or its
            licensors hold the intellectual property rights for all content on
            www.geopaypayment.com. All intellectual property rights are
            reserved. You may access this material from www.geopaypayment.com
            for your personal use, subject to the restrictions set forth in
            these terms and conditions.
          </Typography>

          <Typography variant="body1">You must not:</Typography>

          <ul>
            <li>Republish material from www.geopaypayment.com</li>
            <li>
              Sell, rent, or sublicense material from www.geopaypayment.com
            </li>
            <li>
              Reproduce, duplicate, or copy material from www.geopaypayment.com
            </li>
            <li>Redistribute content from www.geopaypayment.com</li>
          </ul>

          <Typography variant="body1">
            Some sections of this website allow users to post and exchange
            opinions and information. Geopay Payment Private Limited does not
            filter, edit, publish, or review comments before they appear on the
            website. Comments do not necessarily reflect the views and opinions
            of Geopay Payment Private Limited, its agents, and/or affiliates.
            Comments represent the views and opinions of the individuals posting
            them. To the extent permitted by applicable laws, Geopay Payment
            Private Limited shall not be liable for comments or for any
            liability, damages, or expenses caused or suffered as a result of
            any use of, posting of, or appearance of comments on this website.
          </Typography>

          <Typography variant="body1">
            Geopay Payment Private Limited reserves the right to monitor all
            comments and to remove any comments that may be considered
            inappropriate, offensive, or in breach of these Terms and
            Conditions.
          </Typography>

          <Typography variant="body1">
            You warrant and represent that:
          </Typography>

          <ul>
            <li>
              You are entitled to post comments on our website and have all
              necessary licenses and consents to do so.
            </li>
            <li>
              The comments do not infringe upon any intellectual property
              rights, including, but not limited to, copyright, patent, or
              trademark rights of any third party.
            </li>
            <li>
              The comments do not contain any defamatory, libelous, offensive,
              indecent, or otherwise unlawful material that invades privacy.
            </li>
            <li>
              The comments will not be used to solicit or promote business,
              custom, or commercial activities or unlawful activities.
            </li>
          </ul>

          <Typography variant="body1">
            By posting comments on our website, you grant Geopay Payment Private
            Limited a non-exclusive license to use, reproduce, edit, and
            authorize others to use, reproduce, and edit your comments in any
            and all forms, formats, or media.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Hyperlinking To Our Content
          </Typography>

          <Typography variant="body1">
            The following entities may link to our website without prior written
            approval:
          </Typography>

          <ul>
            <li>Government agencies</li>
            <li>Search engines</li>
            <li>News organizations</li>
            <li>
              Online directory distributors may link to our website in the same
              manner as they hyperlink to the websites of other listed
              businesses
            </li>
            <li>
              System-wide Accredited Businesses, except for soliciting
              non-profit organizations, charity shopping malls, and charity
              fundraising groups, which may not hyperlink to our website.
            </li>
          </ul>

          <Typography variant="body1">
            These entities may link to our home page, publications, or other
            website information as long as the link is not deceptive, does not
            falsely imply sponsorship, endorsement, or approval of the linking
            party and its products and/or services, and fits within the context
            of the linking party’s site.
          </Typography>

          <Typography variant="body1">
            We may consider and approve other link requests from the following
            types of entities:
          </Typography>

          <ul>
            <li>Commonly-known consumer and/or business information sources</li>
            <li>Dot.com community sites</li>
            <li>Associations or other groups representing charities</li>
            <li>Online directory distributors</li>
            <li>Internet portals</li>
            <li>Accounting, law, and consulting firms</li>
            <li>Educational institutions and trade associations</li>
          </ul>

          <Typography variant="body1">
            We will approve link requests from these entities if we determine
            that:
          </Typography>

          <ul>
            <li>
              The link will not reflect unfavorably on us or our accredited
              businesses{" "}
            </li>
            <li>The organization does not have any negative records with us</li>
            <li>
              The benefit to us from the visibility of the hyperlink compensates
              for the absence of Geopay Payment Private Limited
            </li>
            <li>The link is in the context of general resource information.</li>
          </ul>

          <Typography variant="body1">
            These entities may link to our home page as long as the link is not
            deceptive, does not falsely imply sponsorship, endorsement, or
            approval of the linking party and its products or services, and fits
            within the context of the linking party’s site.
          </Typography>

          <Typography variant="body1">
            If you are one of the entities listed in paragraph 2 above and are
            interested in linking to our website, you must inform us by sending
            an e-mail to Geopay Payment Private Limited. Please include your
            name, your organization's name, contact information, as well as the
            URL of your site, a list of any URLs from which you intend to link
            to our website, and a list of the URLs on our site to which you
            would like to link. Please allow 2-3 weeks for a response.{" "}
          </Typography>

          <Typography variant="body1">
            Approved entities may hyperlink to our website as follows:
          </Typography>

          <ul>
            <li>By using our corporate name, or</li>
            <li>By using the uniform resource locator being linked to, or</li>
            <li>
              By using any other description of our website that makes sense
              within the context and format of content on the linking party’s
              site.
            </li>
          </ul>

          <Typography variant="body1">
            No use of Geopay Payment Private Limited's logo or other artwork
            will be allowed for linking without a trademark license agreement.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            IFrames
          </Typography>

          <Typography variant="body1">
            You may not create frames around our webpages without prior approval
            and written permission. Frames should not alter the visual
            presentation or appearance of our website in any way.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Content Liability
          </Typography>

          <Typography variant="body1">
            We shall not be held responsible for any content that appears on
            your website. You agree to protect and defend us against all claims
            that arise on your website. No links should appear on any website
            that may be interpreted as libelous, obscene, criminal, or that
            infringes upon, otherwise violates, or advocates the infringement or
            violation of any third-party rights.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Your Privacy
          </Typography>

          <Typography variant="body1">
            Please read our Privacy Policy.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Reservation Of Rights
          </Typography>

          <Typography variant="body1">
            We reserve the right to request the removal of any or all links to
            our website. You agree to promptly remove all links to our website
            upon request. We also reserve the right to amend these terms and
            conditions and its linking policy at any time. By continuously
            linking to our website, you agree to be bound by and follow these
            linking terms and conditions.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Removal of Links from Our Website - Geopay
          </Typography>

          <Typography variant="body1">
            If you come across any link on our website that you find offensive
            or objectionable for any reason, please feel free to contact us at
            any time. While we will consider requests to remove links, we are
            not obligated to do so, nor are we obliged to respond directly to
            your request.{" "}
          </Typography>
          <Typography variant="body1">
            We cannot guarantee the accuracy or completeness of the information
            on this website, and we do not warrant its ongoing availability or
            the timeliness of the content updates.{" "}
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Disclaimer - Geopay
          </Typography>

          <Typography variant="body1">
            To the fullest extent permitted by applicable law, we disclaim all
            representations, warranties, and conditions related to our website
            and its use. This disclaimer does not:
          </Typography>

          <ul>
            <li>
              Limit or exclude our or your liability for death or personal
              injury.
            </li>
            <li>
              Limit or exclude our or your liability for fraud or fraudulent
              misrepresentation.
            </li>
            <li>
              Limit any of our or your liabilities in a way that is not
              permitted under applicable law.
            </li>
            <li>
              Exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
          </ul>

          <Typography variant="body1">
            The limitations and restrictions on liability set forth in this
            section, as well as elsewhere in this disclaimer, are subject to the
            preceding paragraph and govern all liabilities arising under this
            disclaimer, including liabilities arising in contract, tort, or
            breach of statutory duty.
          </Typography>

          <Typography variant="body1">
            As long as we provide the website and its information and services
            free of charge, we will not be liable for any loss or damage of any
            kind.
          </Typography>
        </ResponsiveBox>
      </Grid>
    </Grid>
  );
}

export default TermCondition;
