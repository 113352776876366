import { Grid, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Facebook, Twitter, Instagram } from "@mui/icons-material";

const FooterContainer = styled("footer")(({ theme }) => ({
  background: theme.palette.background.footer,
  padding: theme.spacing(4),
  color: theme.palette.secondary.main,
  bottom: 0,
  width: "100%",
  marginBottom: 0,
  marginTop: "auto",
}));

const FooterContent = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  gap: theme.spacing(2),
}));

const FooterLinks = styled("span")(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.secondary.main,
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const SocialIconsContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
}));

const SocialIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.logo,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

function Footer() {
  const navigate = useNavigate();

  const navigateTo = (route) => {
    navigate(route);
  };

  return (
    <FooterContainer>
      <FooterContent container>
        <Grid item xs={12} md={4}>
          <Typography variant="h6">Connect with Us</Typography>
          <SocialIconsContainer>
            <SocialIcon
              aria-label="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </SocialIcon>
            <SocialIcon
              aria-label="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </SocialIcon>
            <SocialIcon
              aria-label="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </SocialIcon>
          </SocialIconsContainer>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" align="center">
            <FooterLinks onClick={() => navigateTo("/term-conditions")}>
              Terms & Conditions
            </FooterLinks>{" "}
            |{" "}
            <FooterLinks onClick={() => navigateTo("/privacy-policy")}>
              Privacy & Policy
            </FooterLinks>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="body2" align="center">
            &copy; {new Date().getFullYear()}{" "}
            <Typography
              variant="span"
              color={"primary"}
              onClick={() => navigateTo("/home")}
              style={{ cursor: "pointer" }}
            >
              GeoPay.{" "}
            </Typography>
            All Rights Reserved
          </Typography>
          <Typography variant="body2" align="center">
            Copyright ©{new Date().getFullYear()} Design & Developed by{" "}
            <Typography
              variant="span"
              color={"primary"}
              onClick={() => navigateTo("/home")}
              style={{ cursor: "pointer" }}
            >
              Geopay{" "}
            </Typography>
            Tech
          </Typography>
        </Grid>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;
