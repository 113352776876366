import { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Banner from "../common/Banner";
import Association from "../common/Association";
import Banking from "../common/Banking";
import Benefit from "../common/Benefit";
import AllSoultion from "../common/AllSoultion";
import Growth from "../common/Growth";
import Priority from "../common/Priority";
import ContactBaner from "../common/ContactBaner";

const ResponsiveBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(12),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },
}));

function Home({ isLoading }) {
  useEffect(() => {
    isLoading(true);
    setTimeout(() => {
      isLoading(false);
    }, 500);
  }, []);

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <ResponsiveBox>
          <Banner />
        </ResponsiveBox>
      </Grid>
      <Grid item xs={12}>
        <Association />
      </Grid>
      <Grid item xs={12}>
        <Banking />
      </Grid>
      <Grid item xs={12}>
        <AllSoultion />
      </Grid>
      <Grid item xs={12}>
        <Benefit />
      </Grid>
      <Grid item xs={12}>
        <Growth />
      </Grid>
      <Grid item xs={12}>
        <ContactBaner />
      </Grid>
      <Grid item xs={12}>
        <Priority />
      </Grid>
    </Grid>
  );
}

export default Home;
