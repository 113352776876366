import { useEffect } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CustomButton from "../UI/CustomButton";

const ImageSection = styled(Box)(() => ({
  textAlign: "center",
}));

const ServiceContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  boxShadow: theme.shadows[3],
  height: 365,
  borderRadius: 40,
}));

const GetintouchStyle = styled(Grid)(({ theme }) => ({
  background: `linear-gradient(45deg, transparent, ${theme.palette.background.tagLinegradient[1]})`,
  borderRadius: "100% 5% 100% 100%",
  textAlign: "center",
  padding: theme.spacing(8),

  animation: "GetintouchStyle 10s linear infinite",
  "@keyframes GetintouchStyle": {
    "0%": {
      borderRadius: "100% 5% 100% 70%",
    },
    "25%": {
      borderRadius: "100% 5% 100% 180%",
    },
    "50%": {
      borderRadius: "100% 5% 100% 70%",
    },
    "75%": {
      borderRadius: "100% 5% 100% 180%",
    },
    "100%": {
      borderRadius: "100% 5% 100% 70%",
    },
  },
}));

const Partner = ({ isLoading }) => {
  const theme = useTheme();

  useEffect(() => {
    isLoading(true);
    setTimeout(() => {
      isLoading(false);
    }, 500);
  }, []);

  const allPayment = [
    {
      name: "Low to Zero Initial Investment",
      desc: `Start your journey with GeoPay without the burden of a significant upfront investment. Whether you have a smartphone or a computer, you can join us with minimal resources. We believe in reducing barriers to entry and providing equal opportunities for all. Your entrepreneurial journey begins with GeoPay, where innovation meets accessibility.`,
    },
    {
      name: "Expand Your Reach, Gain More Customers",
      desc: `Provide a diverse range of services, including cash withdrawal, bill payments, insurance, and more, to attract a larger customer base to your shop. Increase foot traffic and boost your revenue by becoming a GeoPay partner.`,
    },
    {
      name: "Assured Income",
      desc: `Experience a Steady Income Stream by Offering GeoPay Services or Integrating Our Solutions into Your Current Business Setup to Witness Remarkable Income Growth. Join us, and we'll provide you with the tools and support needed to thrive in the world of digital financial services. With GeoPay, your financial success is just a step away.`,
    },
    {
      name: "Fast & Easy On-Boarding with KYC",
      desc: `Experience a seamless and hassle-free on-boarding journey, complemented by our comprehensive KYC support. Join us today to embark on your path to success.`,
    },
    {
      name: "Secure and Reliable Platform",
      desc: `Rest assured with our 100% secure and highly reliable platform, providing you access to a wide range of digital financial services. Join our trusted network today and explore a world of financial opportunities with confidence.`,
    },
    {
      name: "Maximize Your Earnings",
      desc: `Unlock Exceptional Profit Margins
      Experience unparalleled profit potential with our competitive margins on every service transaction. We are dedicated to providing you with the industry's highest margins, enabling you to attain financial success and independence.`,
    },
    {
      name: "Always There for You",
      desc: `Round-the-Clock Multilingual Technical Support
      Enjoy access to our 24/7 multilingual technical support, ensuring that assistance is always available when you need it. Our team is here to provide guidance, address your queries, and help you navigate any challenges you encounter, ensuring a seamless experience.`,
    },
  ];

  const navigate = useNavigate();

  const navigateTo = (rout) => {
    navigate(rout);
  };

  return (
    <Box>
      <Grid container spacing={2} p={2} style={{ justifyContent: "center" }}>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h4" gutterBottom>
            Join as a Partner
          </Typography>
          <Typography variant="body1" p={2} mb={2}>
            Unlock exciting opportunities and expand your horizons by joining us
            as a partner. Collaborate with GeoPay to embark on a journey of
            growth, innovation, and financial empowerment. As a partner, you'll
            be an integral part of our mission to provide accessible and
            inclusive financial services across India. Together, we'll make a
            difference, fostering economic growth and prosperity in local
            communities. Join us today and be a catalyst for positive change.
          </Typography>
        </Grid>
        {allPayment.map((pay, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <ServiceContainer
              style={{
                background: `linear-gradient(45deg, transparent, ${theme.palette.background.tiles[index]})`,
              }}
            >
              <Grid container spacing={0} p={5}>
                <Grid item xs={12}>
                  <Typography variant="h5">{pay.name}</Typography>
                  <Typography variant="body1" mt={3} paragraph>
                    {pay.desc}
                  </Typography>
                </Grid>
              </Grid>
            </ServiceContainer>
          </Grid>
        ))}
      </Grid>
      <Box>
        <Grid container spacing={4} p={5}>
          <Grid item xs={12} md={6}>
            <ImageSection>
              <img src="/join-us.png" alt="Join Us" style={{ width: "100%" }} />
            </ImageSection>
          </Grid>
          <GetintouchStyle item xs={12} md={6} mt={10}>
            <Typography variant="h5" color="primary" mt={8} gutterBottom>
              Ready to transform your business?
            </Typography>
            <Typography variant="body1" paragraph>
              Join GeoPay today and take the first step toward financial
              empowerment. With our range of services and dedicated support,
              your business will thrive.
            </Typography>
            <CustomButton
              variant="outlined"
              shape={"round"}
              size={"large"}
              style={{ width: 250 }}
              onClick={() => navigateTo("/contact-us")}
            >
              Get Started
            </CustomButton>
          </GetintouchStyle>
        </Grid>
      </Box>
    </Box>
  );
};

export default Partner;
