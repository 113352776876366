import { styled } from "@mui/material/styles";

const AppContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  position: "relative",
  overflow: "hidden",
  background: theme.palette.background.primary,
  color: theme.palette.secondary.main,
}));

function AppContainer({ children }) {
  return <AppContainerStyle>{children}</AppContainerStyle>;
}

export default AppContainer;
