import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const ImageSection = styled(Box)(() => ({
  textAlign: "center",
  marginRight: 10,
  marginTop: 10,
}));

function AllSoultion() {
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Typography
            variant="h4"
            textAlign={"center"}
            fontFamily={"cursive"}
            mt={3}
          >
            One platform for all FinTech needs
          </Typography>
          <Box p={2}>
            <ul>
              <li>
                <strong>Money Transfer:</strong> Our Domestic Money Transfer
                service enables customers to send money across India through our
                extensive retailer network.
              </li>
              <li>
                <strong>Aadhaar Banking:</strong> We provide Aadhaar banking
                services, including Cash Withdrawal, Balance Enquiry, Aadhaar
                Pay, Mini Statement, and Cash Deposit, making basic banking more
                accessible for everyone.
              </li>
              <li>
                <strong>ATM / Mini ATM:</strong> Merchants can set up ATMs to
                perform bank transactions like balance inquiries, cash
                withdrawals, and obtaining mini-statements for their customers.
              </li>
              <li>
                <strong>Digital Payment:</strong> We offer a wide range of
                digital payment solutions to facilitate swift and secure
                business transactions.
              </li>
              <li>
                <strong>Utility Payments:</strong> Our services encompass mobile
                recharges and bill payments, meeting the basic and recurring
                payment needs of walk-in customers through an assisted model.
              </li>
              <li>
                <strong>Travel Services:</strong> As a trusted travel agency, we
                provide discounted prices on air travel and plan to offer
                comprehensive travel packages in the near future.
              </li>
              <li>
                <strong>PAN Card:</strong> We provide hassle-free PAN card
                services through UTI, ensuring quick and efficient processing
                for your tax and financial needs.
              </li>
              <li>
                <strong>Gold Loan:</strong> Unlock the value of your gold with
                our hassle-free gold loan service. Get quick cash without
                parting with your precious possessions.
              </li>
              <li>
                <strong>Credit Card:</strong> Our Credit Card service provides
                convenient and secure payment solutions, offering financial
                flexibility and rewards for your everyday spending.
              </li>
              <li>
                <strong>Insurance:</strong> Our insurance services offer
                comprehensive coverage for your health, life, property, and
                more, ensuring protection and peace of mind in various aspects
                of your life.
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item md={6}>
          <ImageSection>
            <img
              src="/allsolution.png"
              style={{ marginTop: -10, width: "90%" }}
            />
          </ImageSection>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AllSoultion;
