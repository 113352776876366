import { Typography, Container, Box, Paper, styled } from "@mui/material";

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: `linear-gradient(to bottom, ${theme.palette.background.tagLinegradient[0]}, ${theme.palette.background.tagLinegradient[1]})`,
  padding: theme.spacing(4),
  clipPath: "polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0% 75%)",
  boxShadow: theme.shadows[3],
}));

const StyledHeading = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
}));

const StyledText = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  marginTop: theme.spacing(2),
}));

const PriorityComponent = () => {
  return (
    <StyledPaper elevation={3}>
      <Container maxWidth="md">
        <Box textAlign="center">
          <StyledHeading variant="h4" fontFamily={"cursive"}>
            Our Commitment
          </StyledHeading>
          <StyledText variant="body1">
            Our topmost commitment is your success. We are dedicated to
            providing you with the tools, support, and resources you need to
            thrive in your endeavors. Your success is our success, and together,
            we'll achieve great things.
          </StyledText>
        </Box>
      </Container>
    </StyledPaper>
  );
};

export default PriorityComponent;
