import { styled } from "@mui/material/styles";

const PageContainerStyle = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(14),
  overflowX: "hidden",
}));

function PageContainer({ children }) {
  return <PageContainerStyle>{children}</PageContainerStyle>;
}

export default PageContainer;
