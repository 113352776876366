import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const ContainerStyle = styled(Box)(({ theme }) => ({
  background: theme.palette.background.association,
  padding: 10,
  position: "relative",
  overflow: "hidden",
  height: 200,
  [theme.breakpoints.down("sm")]: {
    borderRadius: "80px 0 80px 0",
  },
  [theme.breakpoints.down("md")]: {
    borderRadius: "120px 0 120px 0",
  },
  [theme.breakpoints.up("md")]: {
    borderRadius: "180px 0 180px 0",
  },
  "& .scrolling-images": {
    display: "flex",
    animation: "scrollImages 20s linear infinite",
  },
  "& .scrolling-images img": {
    height: 60,
    margin: "40px 23px",
    objectFit: "cover",
  },
  "@keyframes scrollImages": {
    "0%": {
      transform: "translateX(100%)",
    },
    "100%": {
      transform: "translateX(-100%)",
    },
  },
}));

const Association = () => {
  return (
    <ContainerStyle>
      <Typography
        variant="h4"
        textAlign={"center"}
        style={{ fontFamily: "cursive" }}
      >
        Association With
      </Typography>
      <div className="scrolling-images">
        <img src="/AEPSLogo.png" alt="AEPS" />
        <img src="/iciciLogo.png" alt="ICICI" />
        <img src="/PaytmBankLogo.png" alt="Paytm Bank" />
        <img src="/axisBankLogo.png" alt="Axis Bank" />
        <img src="/BBPSLogo.png" alt="BBPS" />
        <img src="/equitasBankLogo.png" alt="Equitas Bank" />
        <img src="/hitachiLogo.png" alt="Hitachi" />
        <img src="/Cashfree.svg" alt="Cashfree" />
      </div>
    </ContainerStyle>
  );
};

export default Association;
