import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Services from "./components/pages/Services";
import Press from "./components/pages/Press";
import Partner from "./components/pages/Partner";
import Contact from "./components/pages/Contact";
import TermCondition from "./components/pages/TermCondition";
import Privacy from "./components/pages/Privacy";

function AppRoutes({ isLoading }) {
  return (
    <Routes>
      <Route path="/" element={<Home isLoading={isLoading} />} />
      <Route path="/about" element={<About isLoading={isLoading} />} />
      <Route path="/services" element={<Services isLoading={isLoading} />} />
      {/* <Route path="/press-release" element={<Press isLoading={isLoading} />} /> */}
      <Route path="/partner" element={<Partner isLoading={isLoading} />} />
      <Route path="/contact-us" element={<Contact isLoading={isLoading} />} />
      <Route
        path="/term-conditions"
        element={<TermCondition isLoading={isLoading} />}
      />
      <Route
        path="/privacy-policy"
        element={<Privacy isLoading={isLoading} />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default AppRoutes;
