import { Grid, Box, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

const benefits = [
  "Start your Digital Financial Journey with Zero Investment",
  "Fast & Easy On-Boarding with KYC",
  "100% Secure & Reliable Platform for all Digital Financial Services",
  "24/7 Multi-Lingual Technical Support",
  "Best Margins promised on all Service Transactions",
  "99.99% up-time",
];

const CircleContainer = styled(Box)(() => ({
  borderRadius: "80% 20%",
  width: "70%",
  height: 250,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  margin: "auto",
  position: "relative",
  marginTop: 30,
  animation: "circleAnimation 2s ease-in-out",

  "&:last-child::after": {
    display: "none",
  },

  animation: "moveCircle 15s linear infinite",
  "@keyframes moveCircle": {
    "0%": {
      borderRadius: "80% 20%",
    },
    "25%": {
      borderRadius: "20% 80%",
    },
    "50%": {
      borderRadius: "80% 20%",
    },
    "75%": {
      borderRadius: "20% 80%",
    },
    "100%": {
      borderRadius: "80% 20%%",
    },
  },
}));

const Benefit = () => {
  const theme = useTheme();
  return (
    <Box>
      <Typography variant="h4" textAlign={"center"}>
        Benefits of GeoPay
      </Typography>
      <Grid container>
        {benefits.map((benefit, index) => (
          <Grid item xs={6} md={4} key={index}>
            <CircleContainer
              style={{
                animationDelay: `${index * 0.6}s`,
                background: theme.palette.background.tiles[index],
              }}
            >
              <Typography variant="h5" textAlign={"center"} p={4}>
                {benefit}
              </Typography>
            </CircleContainer>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Benefit;
