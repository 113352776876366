import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const ImageSection = styled(Box)(({ theme }) => ({
  background: theme.palette.background.bankingshop,
  textAlign: "center",
  borderRadius: "38% 10% 47% 9%",
  marginLeft: 10,
  marginBottom: 10,
}));

const TextContent = styled(Typography)(({ theme }) => ({
  padding: "5rem",
  fontSize: "1.1rem",
  [theme.breakpoints.down("sm")]: {
    padding: 4,
    fontSize: "1rem",
  },
  [theme.breakpoints.down("md")]: {
    padding: 8,
    fontSize: "1.05rem",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "3rem",
  },
}));

function Banking() {
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item md={6} style={{ width: "100%" }}>
          <ImageSection>
            <img
              src="/Bankingshop.png"
              style={{ marginTop: -10, width: "80%" }}
            />
          </ImageSection>
        </Grid>

        <Grid item md={6}>
          <Typography
            variant="h4"
            textAlign={"center"}
            fontFamily={"cursive"}
            mt={3}
          >
            Begin your own digital banking center
          </Typography>
          <TextContent variant="subtitle1">
            Begin your own digital banking center suggests starting a business
            or venture that focuses on providing digital banking services and
            solutions, with a strong emphasis on both facilitating financial
            transactions and creating an income stream. This could involve
            offering a wide range of financial services online, such as account
            management, money transfers, mobile banking, and digital payment,
            credit card, loan, ATM installation etc. solutions. It implies an
            entrepreneurial opportunity in the financial technology (fintech)
            sector, where you can not only enhance the financial experiences of
            customers by leveraging digital technologies and banking innovations
            but also generate revenue through your services.
          </TextContent>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Banking;
