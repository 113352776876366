import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const ImageSection = styled(Box)(() => ({
  textAlign: "center",
}));

const EllipticShape = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "50%",
  background: `linear-gradient(to bottom right, transparent 20%, ${theme.palette.background.association} 50%)`,
  width: "90%",
  height: "90%",
  left: "5%",
  top: "5%",
  textAlign: "center",
  padding: theme.spacing(4),
  paddingTop: theme.spacing(8),
}));

const About = ({ isLoading }) => {
  useEffect(() => {
    isLoading(true);
    setTimeout(() => {
      isLoading(false);
    }, 500);
  }, []);

  return (
    <Box>
      <Grid container spacing={4} p={2}>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h4" gutterBottom>
            Who We Are
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageSection>
            <img src="/who.png" style={{ width: "60%" }} />
          </ImageSection>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" paragraph>
            A company Incorporated on 10 July 2020 under Companies Act, 1956. At
            GeoPay, we are driven by an unwavering commitment to empower the
            rural regions of India, fostering financial inclusivity across the
            nation. Our steadfast mission is to ensure that financial services
            are easily accessible to all, regardless of their location.
          </Typography>
          <Typography variant="body1" paragraph>
            GEOPAY is a Professional B2B Software Company Based in India. We at
            GEOPAY provide Recharge (Mobile, DTH, DATA Card), Bill Payment
            System (Electricity, Landline, Mobile Bill Payment), Aadhaar Enabled
            Payment System (AEPS), Domestic Money Transfer, Travel Booking(Bus,
            Flight &Hotel Booking), UTI Pan Card, Gold Loan, Insurance etc.
          </Typography>
          <Typography variant="body1" paragraph>
            Our journey began with the establishment of the Indian Youth
            Development Association, with a strong emphasis on advancing
            technical solutions that could benefit communities across India.
            Over time, we evolved into GeoPay Private Limited in 2020, and
            GeoPay has since emerged as India's most trusted rural banking
            platform, providing essential financial services to remote and
            underserved areas. This transformation reflects our unwavering
            commitment to leveraging technology for the betterment of rural
            communities, ensuring they have access to secure and convenient
            financial solutions. In 2021, we further expanded our mission by
            establishing the GeoPay Foundation, dedicated to initiatives such as
            afforestation and various promotional programs, reaffirming our
            dedication to holistic community development.
          </Typography>
          <Typography variant="body1" paragraph>
            We offer a complete online recharge business solution, where
            internet users can recharge their Mobile (postpaid / prepaid), DTH,
            Data card, Landline, Gas, Electricity etc and will make payment
            through payment gateway. If recharge goes fail then amount will be
            credited into customer's wallet. Our online recharge includes cash
            back and reward points features helpful to attract customers. Apart
            from that coupon API can also be integrated.
          </Typography>
          <Typography variant="body1" paragraph>
            In a remarkably short span, we have achieved significant milestones,
            with over 10 lakh individuals relying on our services daily. Our
            team comprises top-tier experts with extensive experience in
            establishing distribution networks, particularly within India. We
            excel at identifying and activating agent services swiftly. Our
            sales team efficiently locates agents within specified territories,
            conducts due diligence, collaborates with customers to activate
            them, and initiates service and project operations. We continuously
            support and monitor agents until they attain a level of operational
            stability. Our focus remains on gaining comprehensive expertise in
            payment and transaction technology.
          </Typography>
          <Typography variant="body1" paragraph>
            Our visionary founders, has always recognized the dire need for
            digital services in rural areas, having personally encountered these
            challenges. They firmly believes that one of the pivotal aspects of
            India's digital transformation is a shift in mindset, as altering
            our perspectives is the initial step toward embracing
            transformation.
          </Typography>
        </Grid>
        <Grid item xs={12} mb={10}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} mt={3}>
              <Typography variant="h5" textAlign={"center"} gutterBottom>
                Mission
              </Typography>
              <EllipticShape>
                <Typography variant="body1" paragraph>
                  Our mission is to reach every corner of our nation, digitize
                  it, and simplify banking services through our extensive retail
                  touchpoints.
                </Typography>
              </EllipticShape>
            </Grid>
            <Grid item xs={12} md={6} mt={3}>
              <Typography variant="h5" textAlign={"center"} gutterBottom>
                Vision
              </Typography>
              <EllipticShape>
                <Typography variant="body1" paragraph>
                  Our vision is to foster self-employment and establish numerous
                  retail touchpoints, enhancing and advancing digital services
                  in rural areas.
                </Typography>
              </EllipticShape>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
