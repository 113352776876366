import { useEffect } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CustomButton from "../UI/CustomButton";

const ImageSection = styled(Box)(() => ({
  textAlign: "center",
}));

const ServiceContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  boxShadow: theme.shadows[3],
  height: 370,
  borderRadius: 40,
}));

const GetintouchStyle = styled(Grid)(({ theme }) => ({
  background: `linear-gradient(45deg, transparent, ${theme.palette.background.tagLinegradient[1]})`,
  borderRadius: "100% 5% 100% 100%",
  textAlign: "center",
  padding: theme.spacing(8),

  animation: "GetintouchStyle 10s linear infinite",
  "@keyframes GetintouchStyle": {
    "0%": {
      borderRadius: "100% 5% 100% 70%",
    },
    "25%": {
      borderRadius: "100% 5% 100% 180%",
    },
    "50%": {
      borderRadius: "100% 5% 100% 70%",
    },
    "75%": {
      borderRadius: "100% 5% 100% 180%",
    },
    "100%": {
      borderRadius: "100% 5% 100% 70%",
    },
  },
}));

const Services = ({ isLoading }) => {
  const theme = useTheme();

  useEffect(() => {
    isLoading(true);
    setTimeout(() => {
      isLoading(false);
    }, 500);
  }, []);

  const allPayment = [
    {
      name: "AePS",
      desc: ` Aadhaar Enabled Payment System (AePS) enables individuals to
  conduct financial transactions using a Micro-ATM.`,
      img: "aepspayment.png",
      height: 100,
    },
    {
      name: "ATM Installation",
      desc: `We offer white-label ATM installations, seamlessly integrating cash access into your business for enhanced customer convenience and increased revenue. Hassle-free ATM installation that aligns with your brand.`,
      img: "atmImg.png",
      height: 100,
    },
    {
      name: "Micro-ATM",
      desc: `We provide Micro ATMs and card swipe machines that enable banks to establish remote connections with their core banking systems.`,
      img: "micro-atm.png",
      height: 100,
    },
    {
      name: "Bill Payment",
      desc: `We provide a range of services, including telecom, DTH, and mobile money recharge, as well as postpaid bill payments. Additionally, we offer utility bill and insurance renewal payment services to cater to diverse financial needs.`,
      img: "pay_image.svg",
      height: 100,
    },
    {
      name: "Money Transfer",
      desc: `
      Through our extensive retailer network, our Domestic Money Transfer services enable customers to effortlessly send money across the entirety of India.`,
      img: "MoneyTransfer.png",
      height: 100,
    },
    {
      name: "IT Service",
      desc: `IT services encompass the various tasks undertaken by an organization to strategize, develop, provide, manage, and oversee information technology services delivered to clients.`,
      img: "Itservice.png",
      height: 100,
    },
    {
      name: "Insurance",
      desc: `Our insurance service offers peace of mind and financial security with a range of coverage options. Whether it's health, life, or property insurance, we have affordable plans to suit your needs. Your future is secure with us.`,
      img: "insurance.png",
      height: 100,
    },
    {
      name: "Pan Card",
      desc: `We provide hassle-free PAN card services through UTI. Our experienced team is dedicated to guiding you through the entire application or update process, making it easy and stress-free.`,
      img: "pan-card.png",
      height: 100,
    },
    {
      name: "Credit Card",
      desc: `Our credit card services provide you with convenient and secure payment solutions, offering flexibility, rewards, and peace of mind for your financial transactions.Our cards are designed to suit your lifestyle.`,
      img: "credit-card.png",
      height: 100,
    },
    {
      name: "Gold Loan",
      desc: `Unlock the value of your gold with our hassle-free Gold Loan services. Secure quick funds by pledging your gold assets, with easy repayment options and competitive interest rates. Your gold, your cash, our commitment.`,
      img: "gold-loan.png",
      height: 100,
    },
    {
      name: "Travel Services",
      desc: `As the principal agent, we provide discounted prices on air travel, making your journeys even more affordable and convenient. Additionally, we have exciting plans to expand our services by offering comprehensive travel packages.`,
      img: "travel.png",
      height: 100,
    },
  ];

  const navigate = useNavigate();

  const navigateTo = (rout) => {
    navigate(rout);
  };

  return (
    <Box>
      <Grid container spacing={2} p={2} style={{ justifyContent: "center" }}>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h4" gutterBottom>
            Our Services
          </Typography>
          <Typography variant="body1" p={2} mb={2}>
            Start your own business with GeoPay's comprehensive range of
            services. We offer a wide array of solutions, from Aadhaar Enabled
            Payment System (AePS) to insurance and more. Join us today and
            empower your entrepreneurial journey.
          </Typography>
        </Grid>
        {allPayment.map((pay, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <ServiceContainer
              style={{
                background: `linear-gradient(45deg, transparent, ${theme.palette.background.tiles[index]})`,
              }}
            >
              <Grid container spacing={0} p={5}>
                <Grid item xs={12}>
                  <ImageSection>
                    <img
                      src={`/${pay.img}`}
                      style={{ height: pay.height }}
                      alt={pay.name}
                    />
                  </ImageSection>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">{pay.name}</Typography>
                  <Typography variant="body1" paragraph>
                    {pay.desc}
                  </Typography>
                </Grid>
              </Grid>
            </ServiceContainer>
          </Grid>
        ))}
      </Grid>
      <Box>
        <Grid container spacing={4} p={5}>
          <Grid item xs={12} md={6}>
            <ImageSection>
              <img src="/join-us.png" alt="Join Us" style={{ width: "100%" }} />
            </ImageSection>
          </Grid>
          <GetintouchStyle item xs={12} md={6} mt={10}>
            <Typography variant="h5" color="primary" mt={8} gutterBottom>
              Ready to transform your business?
            </Typography>
            <Typography variant="body1" paragraph>
              Join GeoPay today and take the first step toward financial
              empowerment. With our range of services and dedicated support,
              your business will thrive.
            </Typography>
            <CustomButton
              variant="outlined"
              shape={"round"}
              size={"large"}
              style={{ width: 250 }}
              onClick={() => navigateTo("/contact-us")}
            >
              Get Started
            </CustomButton>
          </GetintouchStyle>
        </Grid>
      </Box>
    </Box>
  );
};

export default Services;
