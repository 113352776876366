import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  useTheme,
  styled,
} from "@mui/material";
import {
  WhatsApp,
  Business,
  MailOutline,
  PhoneInTalk,
} from "@mui/icons-material";
import CustomButton from "../UI/CustomButton";

const ResponsiveImage = styled("img")(({ theme }) => ({
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "70%",
  },
}));

const ResponsiveBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  paddingTop: 0,

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },
}));

const TypographySuccess = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const ContactBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(4),
  height: 220,
  borderRadius: "10% 0% 10%",
}));

function Contact({ isLoading }) {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    isLoading(true);
    setTimeout(() => {
      isLoading(false);
    }, 500);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    handleInputBlur(e);
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };

    if (name === "name" && !value.trim()) {
      newErrors.name = "Name is required";
    } else {
      delete newErrors.name;
    }

    if (name === "email") {
      if (!value.trim()) {
        newErrors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        newErrors.email = "Email is invalid";
      } else {
        delete newErrors.email;
      }
    }

    if (name === "phone") {
      if (!value.trim()) {
        newErrors.phone = "Phone is required";
      } else if (!/^\d{10}$/.test(value)) {
        newErrors.phone = "Phone is invalid";
      } else {
        delete newErrors.phone;
      }
    }

    if (name === "message" && !value.trim()) {
      newErrors.message = "Message is required";
    } else {
      delete newErrors.message;
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else {
      delete newErrors.name;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    } else {
      delete newErrors.email;
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Phone is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone is invalid";
    } else {
      delete newErrors.phone;
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    } else {
      delete newErrors.message;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const showMessage = (type, message) => {
    const form = {
      name: "",
      email: "",
      phone: "",
      message: "",
    };

    if (type === "success") {
      setSuccessMessage(message);
      setFormData(form);
    } else {
      setErrorMessage(message);
    }
    setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 4000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    isLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    if (validateForm()) {
      try {
        const response = await fetch("/api/contact", {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        console.log(data);
        if (data.status) {
          showMessage(
            "success",
            "We appreciate your message and will get back to you shortly. Our team will be in touch with you soon."
          );
        } else {
          showMessage(
            "error",
            "Failed to send message. Please try again later."
          );
        }
        isLoading(false);
      } catch (error) {
        showMessage("error", "An error occurred. Please try again later.");
        isLoading(false);
      }
    }
  };

  return (
    <ResponsiveBox>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h4" gutterBottom>
            Get in Touch with Us!
          </Typography>
        </Grid>
        <Grid item md={6} textAlign={"center"} style={{ width: "100%" }}>
          <Box>
            <ResponsiveImage src="/contactUs1.png" />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box p={3}>
            {successMessage && (
              <TypographySuccess variant="h6" color="success">
                {successMessage}
              </TypographySuccess>
            )}
            {errorMessage && (
              <Typography variant="h6" color="error">
                {errorMessage}
              </Typography>
            )}
            <form onSubmit={handleSubmit}>
              <TextField
                label="Your Name"
                fullWidth
                margin="normal"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                error={!!errors.name}
                helperText={errors.name}
                required
              />
              <TextField
                label="Your Email"
                fullWidth
                margin="normal"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                error={!!errors.email}
                helperText={errors.email}
                required
              />
              <TextField
                label="Your Phone"
                fullWidth
                margin="normal"
                variant="outlined"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                error={!!errors.phone}
                helperText={errors.phone}
                required
              />
              <TextField
                label="Message"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                variant="outlined"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                error={!!errors.message}
                helperText={errors.message}
                required
              />
              <CustomButton
                style={{ marginTop: 10 }}
                shape={"round"}
                fullWidth
                type="submit"
              >
                Submit
              </CustomButton>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} p={5} justifyContent={"center"}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            textAlign={"center"}
            mb={5}
            fontFamily={"cursive"}
          >
            We are available on
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ContactBox
                style={{
                  background: `linear-gradient(45deg, transparent, ${theme.palette.background.tiles[0]})`,
                }}
              >
                <Business style={{ fontSize: 50 }} />
                <Typography variant="h5">
                  Office Headquarters Address
                </Typography>
                <Typography variant="body1">
                  Sai complex 2nd floor mithanpura bela road muzaffarpur Bihar
                  842002, India
                </Typography>
              </ContactBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ContactBox
                style={{
                  background: `linear-gradient(45deg, transparent, ${theme.palette.background.tiles[2]})`,
                }}
              >
                <MailOutline style={{ fontSize: 50 }} />
                <Typography variant="h5">Email</Typography>
                <Typography variant="body1">
                  Support@geopaysolution.in
                </Typography>
              </ContactBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <ContactBox
                style={{
                  background: `linear-gradient(45deg, transparent, ${theme.palette.background.tiles[3]})`,
                }}
              >
                <PhoneInTalk style={{ fontSize: 50 }} />
                <Typography variant="h5">Phone</Typography>
                <Typography variant="body1">+91-9153998686</Typography>
                <Box style={{ display: "inline-flex" }}>
                  {" "}
                  <WhatsApp />{" "}
                  <span style={{ marginTop: 2, marginLeft: 7 }}>
                    9153998686
                  </span>
                </Box>
              </ContactBox>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </ResponsiveBox>
  );
}

export default Contact;
