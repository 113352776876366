import React, { useState, useEffect } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { lightTheme, darkTheme } from "./styles/theme";
import AppContainer from "./containers/AppContainer";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Routes from "./Routes";
import PageContainer from "./containers/PageContainer";
import Spinner from "./components/common/Spinner";
import "./App.css";

function App() {
  const [themeMode, setThemeMode] = useState("");
  const [loading, setLoading] = useState(true);

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme) {
      setThemeMode(currentTheme);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("theme", themeMode);
  }, [themeMode]);

  const isLoading = (value) => {
    setLoading(value);
  };

  return (
    <ThemeProvider theme={themeMode === "light" ? lightTheme : darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <AppContainer>
          <Header
            isLoading={(value) => isLoading(value)}
            themeMode={themeMode}
            toggleTheme={toggleTheme}
          />
          <PageContainer>
            {loading && (
              <div className="spinner-overlay">
                <Spinner />
              </div>
            )}
            <Routes isLoading={(value) => isLoading(value)} />
          </PageContainer>
          <Footer />
        </AppContainer>{" "}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
