import { useState, useEffect } from "react";
import {
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  DarkModeOutlined,
  FlareOutlined,
  Menu,
  Close,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import CustomButton from "../UI/CustomButton";

const HeaderContainer = styled("div")(({ theme, isScrolled }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "none",
  padding: theme.spacing(2.5),
  flexWrap: "wrap",
  background: isScrolled
    ? theme.palette.background.header
    : theme.palette.background.primary,
  boxShadow: isScrolled ? theme.shadows[3] : "none",
  transition: "background-color 0.3s ease",
  position: "fixed",
  width: "100%",
  zIndex: 10,
}));

const LinksContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

const StyledLink = styled("span")(({ theme, selected }) => ({
  textDecoration: "none",
  color: selected ? theme.palette.primary.main : theme.palette.secondary.nav,
  borderBottom: selected ? `2px solid ${theme.palette.primary.main}` : "none",
  fontSize: 18,
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const StyledLogo = styled("img")(({ theme, isMobile }) => ({
  height: theme.themeMode === "dark" ? 56 : 45,
  width: isMobile ? 120 : 200,
  cursor: "pointer",
}));

const ThemeModeIcon = styled("span")(({ theme }) => ({
  cursor: "pointer",
  padding: 10,
  color: theme.palette.primary.main,
}));

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "70%",
    backgroundColor: theme.palette.background.primary,
  },
  "& .MuiListItem-root": {
    color: theme.palette.secondary.nav,
  },
}));

const CloseButton = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: 0,
  height: 50,
  color: theme.palette.secondary.main,
}));

const ListItemTextStyle = styled(ListItemText)(({ theme, selected }) => ({
  color: selected ? theme.palette.primary.main : theme.palette.secondary.nav,
}));

function Header({ themeMode, toggleTheme, isLoading }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const navigateTo = (route) => {
    navigate(route);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      isLoading(false);
    }, 1000);
  }, []);

  return (
    <HeaderContainer isScrolled={isScrolled}>
      {isMobile && (
        <>
          <Menu
            onClick={() => setIsMenuOpen(true)}
            style={{ cursor: "pointer", color: theme.palette.secondary.main }}
          />
          <CustomDrawer
            anchor="left"
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          >
            <CloseButton>
              <IconButton
                edge="end"
                color="inherit"
                aria-label="close"
                onClick={() => setIsMenuOpen(false)}
              >
                <Close />
              </IconButton>
            </CloseButton>
            <List>
              <ListItem onClick={() => navigateTo("/")}>
                <ListItemTextStyle
                  selected={location.pathname === "/"}
                  primary="Home"
                />
              </ListItem>
              <ListItem onClick={() => navigateTo("/about")}>
                <ListItemTextStyle
                  selected={location.pathname === "/about"}
                  primary="About Us"
                />
              </ListItem>
              <ListItem onClick={() => navigateTo("/services")}>
                <ListItemTextStyle
                  selected={location.pathname === "/services"}
                  primary="Services"
                />
              </ListItem>
              {/* <ListItem onClick={() => navigateTo("/press-release")}>
                <ListItemTextStyle
                  selected={location.pathname === "/press-release"}
                  primary="Press Release"
                />
              </ListItem> */}
              <ListItem onClick={() => navigateTo("/partner")}>
                <ListItemTextStyle
                  selected={location.pathname === "/partner"}
                  primary="Partner"
                />
              </ListItem>
              <ListItem onClick={() => navigateTo("/contact-us")}>
                <ListItemTextStyle
                  selected={location.pathname === "/contact-us"}
                  primary="Contact Us"
                />
              </ListItem>
              {screenWidth <= 400 && (
                <ListItem>
                  <CustomButton
                    variant="outlined"
                    size={"small"}
                    color="primary"
                    style={{ height: 50, width: 200 }}
                    onClick={() => navigateTo("/contact-us")}
                  >
                    Login
                  </CustomButton>
                </ListItem>
              )}
              {screenWidth <= 500 && (
                <ListItem>
                  <CustomButton
                    variant="outlined"
                    size={"small"}
                    color="primary"
                    style={{ height: 50, width: 200 }}
                    onClick={() => navigateTo("/contact-us")}
                  >
                    Register
                  </CustomButton>
                </ListItem>
              )}
            </List>
          </CustomDrawer>
        </>
      )}
      <LinksContainer>
        {!isMobile && (
          <>
            <StyledLink
              color="inherit"
              selected={location.pathname === "/"}
              onClick={() => navigateTo("/")}
            >
              Home
            </StyledLink>
            <StyledLink
              color="inherit"
              selected={location.pathname === "/about"}
              onClick={() => navigateTo("/about")}
            >
              About Us
            </StyledLink>
            <StyledLink
              color="inherit"
              selected={location.pathname === "/services"}
              onClick={() => navigateTo("/services")}
            >
              Services
            </StyledLink>
            {/* <StyledLink
              color="inherit"
              selected={location.pathname === "/press-release"}
              onClick={() => navigateTo("/press-release")}
            >
              Press Release
            </StyledLink> */}
            <StyledLink
              color="inherit"
              selected={location.pathname === "/partner"}
              onClick={() => navigateTo("/partner")}
            >
              Partner
            </StyledLink>
            <StyledLink
              color="inherit"
              selected={location.pathname === "/contact-us"}
              onClick={() => navigateTo("/contact-us")}
            >
              Contact Us
            </StyledLink>
          </>
        )}
      </LinksContainer>
      <StyledLogo
        src={`/logo-${theme.themeMode}.png`}
        alt={"GeoPay"}
        loading="lazy"
        isMobile={isMobile}
      />
      <div>
        {screenWidth > 400 && (
          <CustomButton
            variant="outlined"
            shape="round"
            size={"small"}
            color="primary"
            style={{ marginRight: 10 }}
            onClick={() => navigateTo("/contact-us")}
          >
            Login
          </CustomButton>
        )}
        {screenWidth > 500 && (
          <CustomButton
            variant="outlined"
            shape="round"
            size={"small"}
            color="primary"
            style={{ marginRight: 10 }}
            onClick={() => navigateTo("/contact-us")}
          >
            Register
          </CustomButton>
        )}
        <ThemeModeIcon onClick={toggleTheme}>
          {themeMode === "light" ? (
            <DarkModeOutlined style={{ marginBottom: -8 }} />
          ) : (
            <FlareOutlined style={{ marginBottom: -8 }} />
          )}
        </ThemeModeIcon>
      </div>
    </HeaderContainer>
  );
}

export default Header;
