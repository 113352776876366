import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(MuiButton)(({ theme, shape }) => ({
  borderRadius: shape === "round" ? 100 : 10,
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}));

function CustomButton({ children, shape, size, ...rest }) {
  const buttonSize =
    size === "small" ? "small" : size === "large" ? "large" : "medium";

  return (
    <StyledButton variant="contained" size={buttonSize} shape={shape} {...rest}>
      {children}
    </StyledButton>
  );
}

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  shape: PropTypes.oneOf(["round", "square"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

CustomButton.defaultProps = {
  shape: "square",
  size: "medium",
};

export default CustomButton;
