import { useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const ResponsiveBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(12),
    paddingBottom: theme.spacing(2),
    paddingTop: 0,
  },
}));

function Privacy({ isLoading }) {
  useEffect(() => {
    isLoading(true);
    setTimeout(() => {
      isLoading(false);
    }, 500);
  }, []);

  return (
    <Grid container spacing={12}>
      <Grid item xs={12}>
        <ResponsiveBox>
          <Typography variant="h4" gutterBottom>
            Privacy Policy for Geopay
          </Typography>

          <Typography variant="body1">
            At Geopay, accessible from www.geopay.com, one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that are collected and
            recorded by Geopay and how we use it.
          </Typography>

          <Typography variant="body1">
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </Typography>

          <Typography variant="body1">
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in www.geopay.com. This policy is
            not applicable to any information collected offline or via channels
            other than this website.
          </Typography>

          <Typography variant="h5" mt={4}>
            Consent
          </Typography>
          <Typography variant="body1">
            By utilizing our website, you hereby grant your consent to our
            Privacy Policy and agree to its terms.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Information We Collect
          </Typography>

          <Typography variant="body1">
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </Typography>

          <Typography variant="body1">
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </Typography>

          <Typography variant="body1">
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            How We Utilize Your Information
          </Typography>
          <Typography variant="body1">
            We employ the collected information in various ways, including but
            not limited to:
          </Typography>

          <ul>
            <li>Providing, operating, and maintaining our website</li>
            <li>Enhancing and personalizing our website</li>
            <li>Gaining insights and analyzing website usage</li>
            <li>
              Developing new products, services, features, and functionality
            </li>
            <li>
              Communicating with you directly or through partners for customer
              service, updates, and other website-related information, as well
              as for marketing and promotional purposes
            </li>
            <li>Sending emails</li>
            <li>Detecting and preventing fraudulent activities</li>
          </ul>

          <Typography variant="h5" mt={4} gutterBottom>
            Log Files
          </Typography>

          <Typography variant="body1">
            www.geopaypayment.com adheres to the standard practice of utilizing
            log files. These files record visitor activity when they access
            websites. This process is common among hosting companies and is part
            of hosting services' analytics. Information collected in log files
            includes internet protocol (IP) addresses, browser types, Internet
            Service Providers (ISPs), date and time stamps, referring/exit
            pages, and possibly the number of clicks. None of this information
            is personally identifiable. This data is used for trend analysis,
            site administration, user behavior tracking, and demographic
            information gathering.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Cookies and Web Beacons
          </Typography>

          <Typography variant="body1">
            Similar to other websites, www.geopaypayment.com uses 'cookies' to
            store information such as visitor preferences and the pages visited
            on the website. This information is used to optimize the user
            experience by customizing web page content based on browser type and
            other information.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Google DoubleClick DART Cookie
          </Typography>

          <Typography variant="body1">
            Google, a third-party vendor on our site, also employs cookies
            called DART cookies to display ads to our site visitors based on
            their visits to www.website.com and other internet sites. However,
            visitors have the option to decline the use of DART cookies by
            visiting the Google ad and content network Privacy Policy.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Our Advertising Partners
          </Typography>

          <Typography variant="body1">
            Certain advertisers on our site may utilize cookies and web beacons.
            Below is a list of our advertising partners, each of whom maintains
            their own Privacy Policy. For your convenience, we have provided
            links to their Privacy Policies.
          </Typography>

          <ul>
            <li>
              Google
              <a
                href="[Insert Google Privacy Policy Link]"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
          </ul>

          <Typography variant="body1">
            Third-party ad servers or ad networks use technologies like cookies,
            JavaScript, or Web Beacons in their advertisements and links that
            appear on www.geopaypayment.com. When this occurs, they
            automatically collect your IP address. These technologies are
            employed to gauge the effectiveness of advertising campaigns and to
            customize the advertising content you encounter on websites you
            visit.
          </Typography>

          <Typography variant="body1">
            Please be aware that www.geopaypayment.com does not have access to
            or control over cookies used by third-party advertisers.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Third-Party Privacy Policies
          </Typography>

          <Typography variant="body1">
            Our Privacy Policy does not apply to other advertisers or websites.
            We advise you to review the respective Privacy Policies of these
            third-party ad servers for comprehensive information. These policies
            may include their practices and instructions on how to opt out of
            specific options.
          </Typography>

          <Typography variant="body1">
            You can opt to disable cookies through your individual browser
            settings. For detailed information on managing cookies with specific
            web browsers, consult the respective browser's website.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            CCPA Privacy Rights (Do Not Sell My Personal Information)
          </Typography>

          <Typography variant="body1">
            Under the CCPA, California consumers have various rights, including
            the right to:
          </Typography>

          <ul>
            <li>
              Request a disclosure of the categories and specific pieces of
              personal data collected by a business.
            </li>
            <li>
              Request the deletion of personal data collected by a business.
            </li>
            <li>Request that a business refrain from selling personal data.</li>
          </ul>

          <Typography variant="body1">
            If you make a request, we have one month to respond. To exercise any
            of these rights, please contact us.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            GDPR Data Protection Rights
          </Typography>

          <Typography variant="body1">
            We want to ensure you are fully informed about your data protection
            rights. Every user is entitled to:
          </Typography>

          <ul>
            <li>
              The right to access – Request copies of your personal data, which
              may incur a small fee.
            </li>
            <li>
              The right to rectification – Request the correction of inaccurate
              information or completion of incomplete information.
            </li>
            <li>
              The right to erasure – Request the deletion of your personal data
              under certain conditions.
            </li>
            <li>
              The right to restrict processing – Request the restriction of the
              processing of your personal data under certain conditions.
            </li>
            <li>
              The right to object to processing – Object to the processing of
              your personal data under certain conditions.
            </li>
            <li>
              The right to data portability – Request the transfer of your
              collected data to another organization or directly to you under
              certain conditions.
            </li>
          </ul>

          <Typography variant="body1">
            If you make a request, we have one month to respond. To exercise any
            of these rights, please contact us.
          </Typography>

          <Typography variant="h5" mt={4} gutterBottom>
            Children's Information
          </Typography>

          <Typography variant="body1">
            Our commitment to safeguarding children's internet use is paramount.
            We encourage parents and guardians to oversee, participate in,
            monitor, and guide their children's online activities.
          </Typography>

          <Typography variant="body1">
            www.geopaypayment.com does not knowingly collect Personal
            Identifiable Information from children under the age of 13. If you
            believe your child has provided such information on our website,
            please contact us immediately, and we will promptly remove such
            information from our records.
          </Typography>
        </ResponsiveBox>
      </Grid>
    </Grid>
  );
}

export default Privacy;
